import React, { useState, useEffect } from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import { IoIosArrowRoundDown } from "react-icons/io"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useSpring, animated } from "react-spring"
import VisibilitySensor from "react-visibility-sensor"
import { Spring } from "react-spring/renderprops"

// function bounceInterp(r) {
//   return `translate3d(0, ${15 * Math.sin(r + (2 * Math.PI) / 1.6)}px, 0)`
// }

const Aboutus = props => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { regex: "/aboutus/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 600, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    }
  `)

  console.log("IMAGES", data)
  const image1 = data.allFile.edges[0].node.childImageSharp.fluid
  const image2 = data.allFile.edges[1].node.childImageSharp.fluid
  const image3 = data.allFile.edges[2].node.childImageSharp.fluid

  const headingTrans = useSpring({
    config: { friction: 70 },
    from: { opacity: 0 },
    to: { opacity: 1 },
  })
  const headingTrans2 = useSpring({
    config: { friction: 90 },
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 300,
  })

  const imageTrans = useSpring({
    config: { friction: 50 },
    from: { opacity: 0, transform: "translateY(10rem)" },
    to: { opacity: 1, transform: "translateY(0rem)" },
    delay: 800,
  })

  const imageTrans2 = useSpring({
    config: { friction: 50 },
    from: { opacity: 0, transform: "translateY(10rem)" },
    to: { opacity: 1, transform: "translateY(0rem)" },
    delay: 1000,
  })

  const imageTrans3 = useSpring({
    config: { friction: 50 },
    from: { opacity: 0, transform: "translateX(10rem)" },
    to: { opacity: 1, transform: "translateX(0rem)" },
  })

  return (
    <Layout>
      <Header path={props.path} />

      <div className="aboutus__hero">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
              <animated.h1
                style={headingTrans}
                className="text-center text-md-left"
              >
                About Us
              </animated.h1>

              <animated.h2
                style={headingTrans2}
                className="text-center text-md-left"
              >
                {/* Thousands of people and businesses around the world use
                Storestaker */}
              </animated.h2>
            </div>
            <div className="col-6 ">
              {/* <animated.div
                className="iconbox"
                style={{ transform: radians.interpolate(bounceInterp) }}
              > */}
              <div className="iconbox">
                <IoIosArrowRoundDown className="aboutus__icon" />
              </div>
              {/* </animated.div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="imgcontainer">
        <animated.div style={imageTrans2} className="img img--1">
          <Img className="imgcontainer--2 shadow" fluid={image1} alt="imag1" />
        </animated.div>
        <animated.div style={imageTrans2} className="img img--2">
          <Img className="imgcontainer--2 shadow" fluid={image2} alt="imag1" />
        </animated.div>
      </div>

      <section className="ourmission">
        <div className="container">
          <div className="row flex-column justify-content-center align-items-center ourmission__container">
            <VisibilitySensor>
              {({ isVisible }) => (
                <Spring
                  config={{ tension: 200, friction: 100 }}
                  to={{
                    opacity: isVisible ? 1 : 0,
                  }}
                >
                  {props => (
                    <h2 style={props} className="ourmission__heading1">
                      Our Mission
                    </h2>
                  )}
                </Spring>
              )}
            </VisibilitySensor>

            <h1 className="ourmission__heading2">Mission For Greatness</h1>
            <p className="ourmission__description">
            Aligned with a team of proficient skills in the varied domain of services offered, we stand out to be unique in our areas of offerings.
            </p>
          </div>
          <hr className="hLine" />
        </div>
      </section>

      <section className="aboutus__info">
        <div className="container">
          <div className="row justify-content-center aboutus__box">
            {/* <div className="col-5 d-none d-md-flex"></div> */}
            <div className="col-12 col-md-7   aboutus__content">
              <h1 className="mb-small text-center text-md-left">Who we are</h1>

              <p>
              We are a new-age web development agency offering dynamic and diverse digital solutions under one roof. We offer services in web development, software development, app development, web design, graphic design, logo design, and search engine optimization (SEO). Aligned with a team of proficient skills in the varied domain of services offered, we stand out to be unique in our areas of offerings.
              </p>

             
            </div>
          </div>

          <div className="row aboutus__box">
            <div className="col-12 col-md-7">
              <VisibilitySensor partialVisibility>
                {({ isVisible }) => (
                  <Spring
                    config={{ tension: 200, friction: 100 }}
                    to={{
                      opacity: isVisible ? 1 : 0,
                      transform: isVisible
                        ? "translateX(0rem)"
                        : "translateX(-10rem)",
                    }}
                  >
                    {props => (
                      <Img
                        style={{ transform: "translateX(-10rem)", ...props }}
                        fluid={image3}
                        alt={image3.originalName}
                      />
                    )}
                  </Spring>
                )}
              </VisibilitySensor>
            </div>

            <div className="col-12 col-md-4 aboutus__content aboutus__content--box2">
              <h1 className="mb-small text-center text-md-left">Our Goal</h1>

              <p>
              Our objective is to make our clients happy and satisfied with result-driven services by understanding their organizational challenges and requirement. Our team is competent and dedicated in providing best-in-class solutions in their best possible ways. Our out-of-the-box approach towards making every project exclusive makes us exceptional in the digital space. 
              </p>

             
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Aboutus
